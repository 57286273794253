/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  padding-left: 40px;
  padding-right: 40px;
}
.desk.footdesk {
  max-width: 480px;
}
@media only screen and (max-width: 1023px) {
  .home {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 2;
  }
  .cb-elastic-content-small .home {
    top: 7px;
  }
  .cb-elastic-content-small .home.home-large {
    opacity: 0;
    width: 0;
    -o-transition: top 0.218s, opacity 0.218s, width 0.218s 0.5s;
    -moz-transition: top 0.218s, opacity 0.218s, width 0.218s 0.5s;
    -webkit-transition: top 0.218s, opacity 0.218s, width 0.218s 0.5s;
    transition: top 0.218s, opacity 0.218s, width 0.218s 0.5s;
  }
  .cb-elastic-content-small .home.home-small {
    opacity: 1;
    -o-transition: top 0.218s;
    -moz-transition: top 0.218s;
    -webkit-transition: top 0.218s;
    transition: top 0.218s;
  }
}
@media only screen and (max-width: 1023px) {
  #footer {
    padding: 40px 0;
  }
  .footpart {
    width: 100%;
    margin: 22px 0;
    padding-left: 80px !important;
  }
  .togglenavigation {
    width: 26px;
    height: 22px;
    margin-top: 54px;
  }
  .togglenavigation > span {
    width: 26px;
    height: 4px;
    margin-top: -3px;
  }
  .togglenavigation > .tline-1 {
    margin-top: -12px;
  }
  .togglenavigation > .tline-4 {
    margin-top: 6px;
  }
  .cb-elastic-content-small .togglenavigation {
    margin-top: 34px;
  }
  .cb-elastic-content-small #services {
    margin-top: 22px;
    margin-bottom: 22px;
  }
}
#mobile-navi {
  width: 540px;
}
#mobile-navi {
  right: -540px;
}
#mobile-navi .navi .item .menu {
  padding-left: 80px;
}
#mobile-navi .cb-toggle {
  left: 40px;
}
#services2 {
  display: none;
}
.moodcontent {
  top: 50%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1%;
  margin-left: 1%;
}
.area .part,
.area > .grid table {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.area .tiny {
  width: 48%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 2%;
  margin-left: 2%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 96%;
}
.area > .slim .tiny {
  width: 96%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side h2,
.side .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.side .part,
.side > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side > .slim .part,
.side > .slim.grid table {
  width: 100%;
}
.north {
  width: 100%;
}
.north > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.north h2,
.north .foot {
  margin-right: 1%;
  margin-left: 1%;
}
.north .part,
.north > .grid table {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.north .tiny {
  width: 48%;
}
.north > .slim {
  width: 50%;
}
.north > .slim h2,
.north > .slim .foot,
.north > .slim .part,
.north > .slim.grid table {
  margin-right: 2%;
  margin-left: 2%;
}
.north > .slim .part,
.north > .slim.grid table {
  width: 96%;
}
.north > .slim .tiny {
  width: 96%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.main {
  width: 102%;
  margin-left: -1%;
}
.cb-layout1 .main {
  margin-top: 8px;
}
.cb-layout1 .side {
  margin-top: 30px;
}
.base,
.south {
  width: 102%;
  margin-left: -1%;
}
.north {
  width: 102%;
  margin-left: -1%;
}
.main .seam,
.main .flat,
.main .edge {
  margin-left: 1%;
  margin-right: 1%;
  width: 98%;
}
.main .seam.slim,
.main .flat.slim,
.main .edge.slim {
  width: 48%;
}
.north .seam,
.north .flat,
.north .edge {
  margin-left: 1%;
  margin-right: 1%;
  width: 98%;
}
.north .seam.slim,
.north .flat.slim,
.north .edge.slim {
  width: 48%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-medium.css.map */